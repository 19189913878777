import React from 'react';
import CookieConsent from 'react-cookie-consent';
import './CookieBanner.less';

let bannerOpen = false;

const CookieBanner = () => {
  const bannerStyle = bannerOpen
    ? 'cookie-banner'
    : 'cookie-banner-with-animation';
  bannerOpen = true;

  return (
    <div className={bannerStyle}>
      <CookieConsent
        buttonClasses="cookie-banner__button"
        contentClasses="cookie-banner__content"
        containerClasses="cookie-banner__container"
        buttonText="dismiss"
        disableButtonStyles
        disableStyles
        expires={1} // cookie expires in 1 day
      >
        <p>
          We use some cookies which are essential to make our website work.
          <br />
          <br />
          You have the ability to accept or decline cookies. Most web browsers
          automatically accept cookies, but you can usually modify your browser
          settings to decline cookies if you prefer. If you can choose to
          decline cookies, you may not be able to fully experience all of the
          features of our Websites.
          <br />
          <br />
          Find out more in our
          <a href="/cookies"> Cookie Policy</a>
        </p>
      </CookieConsent>
    </div>
  );
};

export default CookieBanner;

import React, {useState, useEffect} from 'react';
import {Helmet} from 'react-helmet';

// GraphQL
import {useSiteMetadata} from '../../graphql/siteMetadata';

// Components
import Header from '../../components/common/header/Header';
import Footer from '../../components/common/footer/Footer';
import CookieBanner from '../../components/common/cookieBanner/CookieBanner';

// Less
import '../../../static/styles/global.less';
import './Layout.less';

// Assets
import favicon from '../../../static/assets/favicon.ico';
import { createAssetUrl } from '../../utils/helper';

const Layout = ({children}) => {
  const {title, description} = useSiteMetadata();
  const [showBackToTop, setShowBackToTop] = useState(false);

  const scrollFunction = () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  const moveToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollFunction);
    return () => {
      window.removeEventListener('scroll', scrollFunction);
    };
  }, []);

  return (
    <>
      {title && description && (
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <link rel="icon" href={favicon} />
          <html lang="en" />
        </Helmet>
      )}
      <Header />
      <CookieBanner />
      <main>{children}</main>
      {showBackToTop && (
        <button
          className="back-to-top-button"
          onClick={moveToTop}
          type="button"
          aria-label="back-to-top"
        >
          <img src={createAssetUrl("/assets/layout/icon-arrow-up.svg")} alt="arrow-up" className="im im-angle-up" />
        </button>
      )}
      <Footer />
    </>
  );
};

export default Layout;
